import React from "react";

function Footer(){
    return (
        <>
        <div id="footer" className="z-[500] flex align-bottom w-full h-[10vh] place-content-center p-2 bg-[#ffffff]">
            <div className="flex flex-col">
                <div className="flex flex-row self-middle place-content-center space-x-4">
                <a className="rounded-md border border-2 border-black w-32 h-fit p-1 text-center font-bold hover:border-white hover:scale-110 hover:bg-black hover:text-[#ffffff] transition" href="https://www.nanoinkimaging.com/">Exciting</a>
                <a className="rounded-md border border-2 border-black w-32 h-fit p-1 text-center font-bold hover:border-white hover:scale-110 hover:bg-black hover:text-[#ffffff] transition" href="https://www.youtube.com/@smed_asmr">Asmr</a>
                <a className="rounded-md border border-2 border-black w-32 h-fit p-1 text-center font-bold hover:border-white hover:scale-110 hover:bg-black hover:text-[#ffffff] transition" href="mailto:sania.moghe@berkeley.edu">Email</a>
                </div>
                <p className="text-center">© 2024 Sania Moghe</p>
            </div>
        </div>
        </>
    )
}

export default Footer;