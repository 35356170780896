import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import "./index.css"
import "./output.css"

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyA1zGJDzaNAyb0LrIeV7jyH-HgdSe_98ZU",
  authDomain: "iloveyou-4ada4.firebaseapp.com",
  projectId: "iloveyou-4ada4",
  storageBucket: "iloveyou-4ada4.appspot.com",
  messagingSenderId: "220074573063",
  appId: "1:220074573063:web:dda6c67efb0de3175119a2",
  measurementId: "G-BW7GD57CV7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
