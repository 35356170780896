import './App.css';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Navbar from "./templates/Navbar"
import Cover from "./templates/Cover"
import Footer from "./templates/Footer"
import DNA from "./templates/DNA"

function App() {
  return (
    <>
    <DNA/>
    </>
  );
}

export default App;
